<script setup lang="ts">
const {
  artphotolimitedLink,
  kartenliebeLink,
  myposterLink,
} = useFooterLinks();
</script>

<template>
  <div class="px-6 lg:px-0">
    <div class="mt-8 grid grid-cols-1 items-start gap-4 border-t border-grey-mid pt-8 sm:grid-cols-3 lg:grid-cols-4">
      <div class="sm:col-span-3 lg:col-span-1">
        <h3 class="m-0 text-sm font-bold text-dark">
          {{ $t('footer.otherCompanies.headline') }}
        </h3>
      </div>
      <a
        class="block"
        target="_blank"
        :href="myposterLink"
      >
        <h4 class="mb-1 mt-0 text-xs font-semibold text-dark">
          {{ $t('footer.otherCompanies.myposter.headline') }}
        </h4>
        <p class="m-0 text-xs text-grey-semidark">
          {{ $t('footer.otherCompanies.myposter.subline') }}
        </p>
      </a>
      <a
        class="block"
        target="_blank"
        :href="kartenliebeLink"
      >
        <h4 class="mb-1 mt-0 text-xs font-semibold text-dark">
          {{ $t('footer.otherCompanies.kartenliebe.headline') }}
        </h4>
        <p class="m-0 text-xs text-grey-semidark">
          {{ $t('footer.otherCompanies.kartenliebe.subline') }}
        </p>
      </a>
      <a
        class="block"
        target="_blank"
        :href="artphotolimitedLink"
      >
        <h4 class="mb-1 mt-0 text-xs font-semibold text-dark">
          {{ $t('footer.otherCompanies.artphotolimited.headline') }}
        </h4>
        <p class="m-0 text-xs text-grey-semidark">
          {{ $t('footer.otherCompanies.artphotolimited.subline') }}
        </p>
      </a>
    </div>
  </div>
</template>

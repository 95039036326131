<script setup lang="ts">
import { type HeaderIconLinkTransformed } from '@/types/storyblok';

withDefaults(defineProps<{
  icon: Component;
  isPhoneNumber?: boolean;
  link?: HeaderIconLinkTransformed;
}>(), { isPhoneNumber: false, })

const { getStoryblokUrl } = useStoryblokData();

const getLink = () => {
  if (__props.link) {
    return __props.isPhoneNumber
      ? `tel:${__props.link.title}`
      : getStoryblokUrl(__props.link.link);
  }
  return '';
};

const linkComponent = computed(() => (__props.isPhoneNumber || getLink().startsWith('http')
  ? 'a'
  : resolveComponent('NuxtLinkLocale')));
</script>

<template>
  <component
    :is="linkComponent"
    class="icon-link flex items-center text-grey-semidark hover:text-grey-dark"
    v-bind="{
      href: linkComponent === 'a' ? getLink() : { path: getLink() },
    }"
  >
    <span class="flex items-center">
      <component
        :is="icon"
        class="mr-2 size-4"
      />
      <span
        class="whitespace-nowrap text-xs"
        v-text="link?.title || ''"
      />
    </span>
  </component>
</template>

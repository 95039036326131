<script lang="ts" setup>
import { XIcon } from 'lucide-vue-next';
import { colors } from 'shared-components';
import { type MultiLinkUnionType } from '@/types/storyblok';

withDefaults(defineProps<{
  backgroundColor: string;
  buttonLink?: MultiLinkUnionType | null;
  buttonText?: string | null;
  end: string;
  id: string;
  promoCode?: string | null;
  showCountdown?: boolean;
  title: string;
  titleUppercased?: boolean;
  topText?: string | null;
}>(), { backgroundColor: () => (colors.dark), })

const cookieId = `promobar-${__props.id}-closed`;

const closedByUserCookie = useCookie<boolean>(cookieId);

const { getStoryblokUrl } = useStoryblokData();

const now = useState('promobar-now', () => new Date());
const saleEndDate = useState('promobar-end', () => parseStoryblokDate(__props.end));

const isSaleEndInTheFuture = computed(() => saleEndDate.value.getTime() > now.value.getTime());

const closePromobar = () => {
  closedByUserCookie.value = true;
};

const isDarkBackground = computed(() => isDarkColor(__props.backgroundColor));

const { isBpMobile } = useAppBreakpoints();

const buttonUrl = computed(() => getStoryblokUrl(__props.buttonLink));

const handleClick = () => {
  if (isBpMobile.value && buttonUrl) {
    window.location.href = getStoryblokUrl(__props.buttonLink);
  }
};

const hasDetails = computed(() => __props.showCountdown || __props.promoCode);
</script>

<template>
  <aside
    v-if="closedByUserCookie !== true && isSaleEndInTheFuture"
    class="relative bg-dark py-1.5 md:py-2"
    :class="[isDarkBackground ? 'text-white' : 'text-dark']"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div
      class="container flex cursor-pointer flex-wrap items-center md:cursor-default md:flex-nowrap"
      @click="handleClick"
    >
      <div
        class="w-1/2 justify-start md:order-1 md:w-1/4"
        :class="[hasDetails ? 'flex' : 'hidden md:flex']"
      >
        <template v-if="showCountdown">
          <div class="flex h-9 shrink-0 items-center justify-start rounded-md bg-white p-0.5 text-xs">
            <span class="px-2 py-1 uppercase leading-none text-red">
              <strong>{{ $t('common.sale') }}</strong>
              <span class="hidden md:block">{{ $t('common.saleEnds') }}</span>
            </span>
            <ClientOnly>
              <Countdown
                v-if="saleEndDate"
                time-blocks
                class="md:bg-transparent! md:text-dark"
                :background-color="backgroundColor"
                :class="{
                  'md:bg-transparent! md:text-dark': isDarkBackground,
                }"
                :end="saleEndDate"
              />
            </ClientOnly>
          </div>
        </template>
        <template v-else>
          <div class="h-8 w-full" />
        </template>
      </div>

      <div
        class="w-1/2 justify-end md:order-3 md:w-1/4 md:pr-6 lg:pr-0"
        :class="[hasDetails ? 'flex' : 'hidden md:flex']"
      >
        <PromoCodeCopyButton
          v-if="promoCode"
          class="justify-self-end"
          :is-dark-background="isDarkBackground"
          :promo-code="promoCode"
          @click.stop
        />
        <Button
          v-if="buttonLink && buttonText"
          as-child
          class="ml-5 hidden shrink-0 border-transparent md:flex"
          :variant="backgroundColor === colors['purple-hazy'] ? 'info' : 'secondary'"
        >
          <NuxtLink :to="getStoryblokUrl(buttonLink)">
            {{ buttonText }}
          </NuxtLink>
        </Button>
      </div>

      <div
        class="flex w-full justify-center md:order-2 md:mt-0 md:w-1/2 md:border-t-0 md:py-0"
        :class="[hasDetails ? 'mt-2 border-t pt-2' : 'py-1.5', isDarkBackground ? 'text-white/20' : 'text-dark/20']"
      >
        <div
          class="text-center text-xs leading-tight sm:text-sm md:text-base"
          :class="[isDarkBackground ? 'text-white' : 'text-dark']"
        >
          <span
            v-if="topText"
            class="hidden text-balance uppercase leading-tight md:block"
          >{{ topText }}</span>
          <strong
            v-if="title"
            class="mx-4 block text-balance leading-tight md:px-2"
            :class="{ 'uppercase': titleUppercased }"
          >
            <FormattedMarkdown
              allow-text-formatting
              :content="title"
            />
          </strong>
        </div>
      </div>
    </div>
    <button
      class="absolute bottom-0 right-2 flex size-8 items-center justify-center opacity-50 hover:opacity-100 md:bottom-auto md:right-1"
      size="icon"
      variant="ghost"
      :class="[
        hasDetails ? 'md:top-1/2 md:-mt-4' : 'top-1/2 -translate-y-1/2 md:top-1/2 md:-translate-y-1/2',
      ]"
      @click="closePromobar()"
    >
      <XIcon class="size-6" />
    </button>
  </aside>
</template>

import { joinRelativeURL } from 'ufo';

export const useFooterLinks = () => {
  const { locale } = useI18n();

  const supportPhoneNumber = computed(() => {
    if (locale.value === 'uk') {
      return '+44 (0) 2034990238';
    }

    if (locale.value.startsWith('nl')) {
      return '+31 (0) 204990346';
    }

    if (locale.value.startsWith('fr')) {
      return '+33 (0) 177696810';
    }

    return '+49 (0) 3022385614';
  });

  const aboutUsLink = computed(() => `https://inside.juniqe.com/${locale.value === 'uk'
    ? 'uk'
    : 'de'}`);

  const getPathForLocale = (paths: Record<string, string>, fallback: string) => Object.entries(paths).map(([
    key,
    path,
  ]) => [
    key,
    joinRelativeURL(`/${locale.value}`, path),
  ])
    .find(([ key ]) => locale.value.startsWith(key))
    ?.at(1) ?? joinRelativeURL(`/${locale.value}`, fallback);

  const businessCustomersLink = computed(() => getPathForLocale({
    es: '/empresas',
    fr: '/entreprises',
    it: '/aziende',
    nl: '/zakelijke-klanten',
    uk: '/business-customers',
  }, '/geschaeftskunden'));

  const myposterLink = computed(() => {
    switch (locale.value) {
      case 'de-at':
        return 'https://myposter.at';
      case 'de-ch':
        return 'https://myposter.ch';
      case 'de-li':
        return 'https://myposter.li';
      case 'de-lu':
        return 'https://myposter.lu';
      case 'nl-be':
        return 'https://myposter.be';
      default:
        if (locale.value.startsWith('it')) {
          return 'https://myposter.it';
        }
        if (locale.value.startsWith('es')) {
          return 'https://myposter.es';
        }
        if (locale.value.startsWith('fr')) {
          return 'https://myposter.fr';
        }
        if (locale.value.startsWith('nl')) {
          return 'https://myposter.nl';
        }
        return 'https://myposter.de';
    }
  });

  const kartenliebeLink = computed(() => {
    if (locale.value === 'de-at') {
      return 'https://kartenliebe.at';
    }

    if (locale.value === 'de-ch') {
      return 'https://kartenliebe.ch';
    }

    return 'https://kartenliebe.de';
  });

  const artphotolimitedLink = computed(() => {
    if (locale.value.startsWith('de')) {
      return 'https://artphotolimited.com/de';
    }

    if (locale.value === 'uk') {
      return 'https://artphotolimited.com/uk-en';
    }

    return 'https://artphotolimited.com';
  });

  const impressumLink = computed(() => getPathForLocale({
    de: '/impressum',
    es: '/aviso-legal',
    fr: '/mentions-legales',
    it: '/stampa',
    nl: '/impressum',
    uk: '/imprint',
  }, '/impressum'));

  const faqLink = computed(() => {
    if (locale.value === 'uk') {
      return 'https://support.juniqe.com/hc/en-gb';
    }

    if (locale.value.startsWith('fr')) {
      return 'https://support.juniqe.com/hc/fr-fr';
    }

    if (locale.value.startsWith('nl')) {
      return 'https://support.juniqe.com/hc/nl-nl';
    }

    if (locale.value.startsWith('es') || locale.value.startsWith('it')) {
      return 'https://support.juniqe.com/hc/en-150';
    }

    return 'https://support.juniqe.com/hc/de-de';
  });

  const imprintLink = computed(() => getPathForLocale({
    de: '/impressum',
    es: '/aviso-legal',
    it: '/stampa',
    uk: '/imprint',
  }, '/impressum'));

  const pressLink = computed(() => {
    if (locale.value === 'uk') {
      return 'https://inside.juniqe.com/uk/press';
    }

    return 'https://inside.juniqe.com/de/presse';
  });

  const jobsLink = computed(() => {
    if (locale.value === 'uk') {
      return 'https://inside.juniqe.com/uk/jobs';
    }

    return 'https://inside.juniqe.com/de/jobs';
  });

  const privacyLink = computed(() => getPathForLocale({
    de: '/datenschutz',
    es: '/politica-de-privacidad',
    fr: '/protection-donnees',
    it: '/privacy',
    nl: '/gegevensbescherming',
    uk: '/privacy-policy',
  }, '/datenschutz'));

  const cancellationPolicyLink = computed(() => getPathForLocale({
    es: '/politica-de-cancelacion',
    fr: '/droit-retractation',
    it: '/politica-cancellazione',
    nl: '/annuleringsvoorwaarden',
    uk: '/cancellation-policy',
  }, '/widerrufsrecht'));

  const deliveryTimeLink = computed(() => {
    if (locale.value === 'uk') {
      return 'https://support.juniqe.com/hc/en-gb/categories/12643327860241-Order-Shipping-Returns';
    }

    if (locale.value.startsWith('nl')) {
      return 'https://support.juniqe.com/hc/nl-nl/categories/12643327860241-Bestelling-verzending-retouren';
    }

    if (locale.value.startsWith('fr')) {
      return 'https://support.juniqe.com/hc/fr-fr/categories/12643327860241-Commande-Livraison-Retours';
    }

    if (locale.value.startsWith('it') || locale.value.startsWith('es')) {
      return 'https://support.juniqe.com/hc/en-150/categories/12643327860241-Order-Shipping-Returns';
    }

    return 'https://support.juniqe.com/hc/de-de/categories/12643327860241-Bestellung-Versand-Retoure';
  });

  const termsLink = computed(() => getPathForLocale({
    es: '/terminos-y-condiciones',
    fr: '/cgv',
    it: '/termini-condizioni',
    nl: '/av',
    uk: '/terms',
  }, '/agb'));

  const giftVouchersLink = computed(() => getPathForLocale({
    es: '/cheque-regalo',
    fr: '/carte-cadeau',
    it: '/buono-regalo',
    nl: '/cadeaubon',
    uk: '/voucher',
  }, '/gutschein'));

  return {
    aboutUsLink,
    artphotolimitedLink,
    businessCustomersLink,
    cancellationPolicyLink,
    deliveryTimeLink,
    faqLink,
    giftVouchersLink,
    impressumLink,
    imprintLink,
    jobsLink,
    kartenliebeLink,
    myposterLink,
    pressLink,
    privacyLink,
    supportPhoneNumber,
    termsLink,
  };
};

<script setup lang="ts">
import MainNavigationTabs from './MainNavigationTabs.vue';
import type { DefaultLayoutQuery } from '#gql';

withDefaults(defineProps<{
  mainNavigationItems: DefaultLayoutQuery['mainNavigation']['items'];
}>(), {  })

const { locale } = useI18n();
const localesWithFlyout = [
  'de',
  'de-ch',
  'de-at',
];

const {
  closeFlyout,
  firstLevelActiveIndex,
  hasItems,
} = useNavigationActiveIndex();

const activeFlyoutHasItems = computed(() => hasItems.value);

const flyoutItemId = computed(() => (localesWithFlyout.includes(locale.value) && firstLevelActiveIndex.value > -1
  ? __props.mainNavigationItems?.[firstLevelActiveIndex.value]._uid || null
  : null));
</script>

<template>
  <div
    class="main-navigation relative flex w-full min-w-0 flex-col whitespace-nowrap border-b border-grey-mid"
    @mouseleave="closeFlyout()"
  >
    <span>
      <UiScroller
        has-full-height-buttons
        class="justify-center"
      >
        <template #scrollerContent>
          <span class="flex flex-nowrap justify-start">
            <MainNavigationTabs :tabs="mainNavigationItems" />
          </span>
        </template>
      </UiScroller>

      <FlyoutNavigation
        v-if="flyoutItemId"
        :key="flyoutItemId"
        class="hidden md:block"
        :flyout-item-id="flyoutItemId"
      />
    </span>
    <LoadingOverlay
      class="top-[44px]! hidden md:flex"
      :is-loading="(!!flyoutItemId) && activeFlyoutHasItems"
    />
  </div>
</template>

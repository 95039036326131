<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

defineProps<{
  trustpilotInformation?: DefaultLayoutQuery['trustpilotInformation'];
  trustpilotReviews?: DefaultLayoutQuery['trustpilotReviews'];
}>();

const copyright = computed(() => `© ${new Date().getFullYear()} JUNIQE`);
</script>

<template>
  <footer class="w-full max-w-full overflow-x-hidden pt-12">
    <TrustpilotSection
      v-if="trustpilotInformation && trustpilotReviews?.length"
      class="relative z-20"
      :reviews="trustpilotReviews"
      :total-reviews="trustpilotInformation.totalReviews"
      :trust-score="trustpilotInformation.trustScore"
    />
    <div class="-mt-8 bg-purple-extra-light pt-8">
      <NewsletterSignup />

      <ContactInformation />

      <PaymentMethods />
    </div>

    <div class="bg-white">
      <div class="container max-w-7xl pb-4 pt-8">
        <FooterLinks />

        <OtherCompanies />

        <div class="px-6 lg:px-0">
          <div class="mt-8 border-t border-grey-mid pt-4 text-xs text-grey-semidark">
            <div class="flex flex-col justify-between gap-2 md:flex-row md:gap-8">
              <div class="shrink-0">
                {{ copyright }}
              </div>

              <div class="md:text-right">
                {{ $t('footer.footnote') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import {
  MailIcon,
  TicketPercentIcon,
  XIcon,
} from 'lucide-vue-next';
import { Logger } from 'utils';

const SHOW_POPUP_AFTER_SCROLL_DELAY = 1000;

const { locale } = useI18n();
const { BASE_URL } = useBaseUrl();
const { getToken } = useReCaptcha();
const isDismissed = useCookie<boolean>('newsletter-popup-dismissed');

const isVisible = ref(false);
const elementRef = ref<HTMLDivElement | null>(null);
const contentRef = ref<HTMLDivElement | null>(null);
const isExpanded = ref(false);

const togglePopup = () => {
  isExpanded.value = !isExpanded.value;
};

const dismissPopup = () => {
  isDismissed.value = true;
};

const formRef = ref<HTMLFormElement | null>(null);
const token = ref<string | null>(null);

const signUp = async () => {
  try {
    token.value = await getToken('newsletter');
  } catch (error) {
    Logger.error('Newsletter recaptcha failed', error);
  }

  if (!token.value) {
    Logger.error('No newsletter recaptcha token');
  }

  if (formRef.value) {
    nextTick(() => {
      formRef.value?.submit();
      isDismissed.value = true;
    });
  } else {
    Logger.error('Newsletter form reference is null');
  }
};

onClickOutside(elementRef, () => {
  if (elementRef.value && isExpanded.value) {
    isExpanded.value = false;
  }
});

const {
  height,
  width,
} = useElementBounding(contentRef);

useWindowScroll({
  onScroll: () => {
    if (!isDismissed.value && !isVisible.value) {
      setTimeout(() => {
        isVisible.value = true;
      }, SHOW_POPUP_AFTER_SCROLL_DELAY);
    }
  },
});
</script>

<template>
  <div
    v-if="!isDismissed"
    ref="elementRef"
    class="fixed left-6 z-50 size-auto overflow-hidden rounded-3xl border-2 border-white  shadow-2xl transition-all duration-300"
    :class="[isVisible ? 'bottom-6 opacity-100' : '-bottom-16 opacity-0', isExpanded ? 'bg-purple-extra-light' : 'bg-purple']"
  >
    <div
      class="origin-bottom-left overflow-hidden transition-all duration-300 ease-in-out"
      :style="{
        width: isExpanded ? `${width}px` : '0px',
        height: isExpanded ? `${height}px` : '0px',
      }"
    >
      <div
        ref="contentRef"
        class="h-auto w-[min(calc(100vw-3rem),384px)] p-5"
      >
        <div class="mb-3 flex items-center justify-end">
          <Button
            class="size-10 rounded-full border-0 shadow-none"
            size="icon"
            variant="secondary"
            @click="dismissPopup"
          >
            <XIcon class="size-8" />
          </Button>
        </div>
        <h3 class="m-0 text-xl font-bold text-purple-dark md:text-2xl">
          {{ $t('newsletterPopup.headline') }}
        </h3>
        <p class="mt-2 text-xs text-semidark">
          {{ $t('newsletterPopup.description') }}
        </p>
        <form
          ref="formRef"
          accept-charset="utf-8"
          class="mt-4 w-full"
          method="post"
          :action="`${BASE_URL}/${locale}/newsletter/subscribe`"
          @submit.prevent="signUp"
        >
          <Input
            autocomplete="email"
            class="h-auto px-4 py-3 text-dark"
            name="email"
            type="email"
            :placeholder="$t('newsletterPopup.emailPlaceholder')"
          />
          <input
            name="reCaptchaToken"
            type="hidden"
            :value="token"
          />
          <Button
            class="mt-4 w-full"
            type="submit"
            variant="info"
          >
            <MailIcon class="size-4" />
            {{ $t('newsletterPopup.submitButton') }}
          </Button>
        </form>
      </div>
    </div>
    <div
      class="flex max-w-[min(calc(100vw-3rem),384px)] overflow-hidden text-white transition-all duration-300 ease-in-out"
      :class="[isExpanded ? '-mb-10' : 'mb-0']"
    >
      <button
        class="flex max-w-full items-center gap-2 overflow-hidden py-2 pl-4 pr-0 font-bold md:pr-4"
        @click="togglePopup"
      >
        <TicketPercentIcon class="mt-0.5 size-6" />

        <hr class="h-3 w-0 flex-1 border-l border-purple-dark" />

        <span class="block truncate text-sm leading-none">{{ $t('newsletterPopup.minimizedText') }}</span>
      </button>
      <button
        class="span flex size-10 items-center justify-center text-dark md:hidden"
        @click.stop="dismissPopup"
      >
        <XIcon class="size-4" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n();
const paymentMethods: {
  condition?: () => boolean;
  id: string;
  title: string;
}[] = [
  {
    id: 'amazon-pay',
    title: 'Amazon Pay',
  },
  {
    condition: () => locale.value === 'de',
    id: 'maestro',
    title: 'Maestro',
  },
  {
    condition: () => [
      'de',
      'de-at',
      'de-ch',
    ].includes(locale.value),
    id: 'invoice',
    title: 'Kauf auf Rechnung',
  },
  {
    id: 'paypal',
    title: 'PayPal',
  },
  {
    id: 'american-express',
    title: 'American Express',
  },
  {
    id: 'mastercard',
    title: 'Mastercard',
  },
  {
    id: 'visa',
    title: 'Visa',
  },
  {
    condition: () => [
      'de-li',
      'de-ch',
    ].includes(locale.value),
    id: 'postfinance',
    title: 'PostFinance',
  },
  {
    condition: () => locale.value.startsWith('fr'),
    id: 'cb',
    title: 'CB',
  },
  {
    condition: () => locale.value.startsWith('nl'),
    id: 'ideal',
    title: 'iDEAL',
  },
  {
    condition: () => locale.value.includes('-be'),
    id: 'payconiq',
    title: 'Payconiq',
  },
  {
    condition: () => locale.value.startsWith('de-ch'),
    id: 'twint',
    title: 'Twint',
  },
  {
    condition: () => locale.value.includes('-be'),
    id: 'bancontact',
    title: 'Bancontact',
  },
  {
    condition: () => locale.value.startsWith('es'),
    id: 'transferencia',
    title: 'Transferencia',
  },
  {
    id: 'apple-pay',
    title: 'Apple Pay',
  },
  {
    id: 'google-pay',
    title: 'Google Pay',
  },
];

const filteredPaymentMethods = computed(() => paymentMethods.filter((method) => (method.condition
  ? method.condition()
  : true)));
</script>

<template>
  <div class="container py-8 lg:py-12">
    <div class="flex flex-wrap justify-center gap-3 px-6 lg:px-0">
      <div
        v-for="method in filteredPaymentMethods"
        :key="method.id"
        class="overflow-hidden rounded-md border border-dark/5"
        :title="method.title || ''"
      >
        <NuxtImg
          aspect-ratio="74/32"
          loading="lazy"
          quality="80"
          :alt="method.title || ''"
          :modifiers="{ f: 'auto' }"
          :src="`/images/payment-methods/${method.id}.jpg`"
          :width="74"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  buttonLink?: string;
  countBadge?: number | null;
  icon: Component;
  iconOrientation?: string;
  imageInfo?: string;
  isLinkExternal?: boolean;
  withBorder?: boolean;
}>(), { buttonLink: '',countBadge: null,imageInfo: null,isLinkExternal: false,withBorder: false, })
defineEmits([ 'click' ]);

const localePath = useLocalePath();

const onClick = async () => {
  if (!__props.buttonLink) {
    return;
  }

  await navigateTo(localePath({ path: __props.buttonLink }), { external: __props.isLinkExternal });
};

const iconClasses = computed(() => ([
  { 'justify-start': __props.iconOrientation === 'left' },
  { 'justify-end': __props.iconOrientation === 'right' },
  { 'justify-center': !__props.iconOrientation },
  { 'rounded-full border border-grey transition duration-300 ease-in-out hover:border-2 hover:border-dark': __props.withBorder },
]));
</script>

<template>
  <div class="relative">
    <button
      class="flex size-8 items-center justify-center bg-white p-0 md:ml-4 md:size-10"
      type="button"
      :class="iconClasses"
      @click="onClick(); $emit('click')"
    >
      <div
        v-if="imageInfo"
        class="m-0 flex size-5 items-center justify-center rounded-full bg-dark md:size-6"
      >
        <span
          class="text-sm font-bold uppercase text-white"
          v-text="imageInfo.charAt(0)"
        />
      </div>
      <component
        :is="icon"
        v-else
        class="size-5 text-dark md:size-6"
      />
    </button>
    <ClientOnly>
      <CountBadge
        v-if="countBadge !== null"
        :count="countBadge"
        :has-border="withBorder"
      />
    </ClientOnly>
  </div>
</template>

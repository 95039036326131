<script setup lang="ts">
import { useDialog } from '@/composables/useDialog';
import type { AuthDialogView } from '@/types/dialogs';

const {
  activeView,
  closeDialog,
  isDialogOpen,
} = useDialog<AuthDialogView>('AuthDialog', 'login');
const viewComponent = computed(() => {
  switch (activeView!.value) {
    case 'registration':
      return resolveComponent('RegistrationForm');
    case 'forgot-password':
      return resolveComponent('ForgotPasswordForm');
    default:
      return resolveComponent('LoginForm');
  }
});
</script>

<template>
  <DialogRoot
    :open="isDialogOpen"
    @update:open="open => !open && closeDialog()"
  >
    <DialogContent class="max-w-md bg-grey-extra-light p-0 outline-hidden">
      <component :is="viewComponent" />
    </DialogContent>
  </DialogRoot>
</template>

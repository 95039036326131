<script setup lang="ts">
import { ArrowRightIcon } from 'lucide-vue-next';
import { type MultiLinkUnionType } from '@/types/storyblok';
import type { NavigationItemQuery } from '#gql';

type NavigationItemGroup = NonNullable<NavigationItemQuery['navigationItem']['groups']>[number];

withDefaults(defineProps<{ navigationItemGroup: NavigationItemGroup }>(), {  })

const {
  thirdLevelActiveIndex,
  updateFirstLevelActiveIndex,
  updateThirdLevelActiveIndex,
} = useNavigationActiveIndex();

const { getStoryblokUrl } = useStoryblokData();

const thirdLevelData = computed(() => {
  const mergedColumnData: Array<NonNullable<NonNullable<NavigationItemGroup['columns']>[number]['blocks']>[number]> = [];
  __props.navigationItemGroup.columns?.forEach((column) => {
    column.blocks?.forEach((block) => {
      mergedColumnData.push(block);
    });
  });
  return mergedColumnData;
});

const handleLinkInteraction = (link?: MultiLinkUnionType | null) => {
  updateFirstLevelActiveIndex(-1);
  if (link) {
    window.location.href = getStoryblokUrl(link);
  }
};
</script>

<template>
  <div
    v-if="thirdLevelData"
    class="flyout-columns-tablet relative table h-auto w-full bg-white p-5 pb-3 lg:hidden"
  >
    <span class="grid h-full grid-cols-2 gap-5 overflow-y-auto overflow-x-hidden after:absolute after:right-1/2 after:top-0 after:h-full after:border-r after:border-grey after:bg-grey">
      <span class="col-span-1">
        <span
          v-for="(block, index) in thirdLevelData"
          :key="block.id"
          class="relative flex justify-between border-white p-3 transition"
          :class="{ 'z-20 w-[calc(100%+10px)] translate-x-2.5 rounded-l-lg border border-grey border-r-white bg-white font-semibold shadow-[5px_0_0_white]': index === thirdLevelActiveIndex }"
          @mouseover="updateThirdLevelActiveIndex(index)"
          @touchstart.prevent="updateThirdLevelActiveIndex(index)"
        >
          <span
            class="flex max-w-[90%] cursor-default text-sm text-dark"
            v-text="block.title"
          />
          <ArrowRightIcon class="mr-4 size-3 self-center" />
        </span>
      </span>
      <span class="z-20 col-span-1 p-3 shadow-[-10px_0_0_white]">
        <span
          v-for="(block, blockIndex) in thirdLevelData"
          :key="block.id"
        >
          <span v-if="blockIndex === thirdLevelActiveIndex">
            <NuxtLink
              class="block w-full cursor-pointer"
              :to="getStoryblokUrl(block.link)"
              @click="handleLinkInteraction(block.link)"
              @touchstart.prevent="handleLinkInteraction(block.link)"
            >
              <span
                class="mb-2 block text-sm font-semibold text-dark"
                :class="{ 'cursor-pointer hover:text-semidark': !!block.link }"
                v-text="block.title"
              />
            </NuxtLink>
            <span
              v-for="flyoutLink in block.links"
              :key="flyoutLink.id"
              class="mb-2"
              @click="updateFirstLevelActiveIndex(-1)"
              @touchstart="updateFirstLevelActiveIndex(-1)"
            >
              <NuxtLink
                class="mt-2 block w-full cursor-pointer text-xs leading-4"
                :to="getStoryblokUrl(flyoutLink.link)"
                @click="handleLinkInteraction(flyoutLink.link)"
                @touchstart.prevent="handleLinkInteraction(flyoutLink.link)"
              >
                <span
                  class="cursor-pointer text-grey-semidark hover:text-grey-dark"
                  v-text="flyoutLink.title"
                />
              </NuxtLink>
            </span>
          </span>
        </span>
      </span>
    </span>
  </div>
</template>

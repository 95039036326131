<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

defineProps<{ headerData: DefaultLayoutQuery['header']; promobar?: NonNullable<DefaultLayoutQuery['promobars']>[number] }>();

const { isDesktopOrTablet } = useAppBreakpoints();
</script>

<template>
  <Promobar
    v-if="promobar"
    v-bind="promobar"
  />
  <header class="header-component relative flex w-full items-center justify-center border-grey text-grey-semidark md:mb-3 md:border-b md:border-none">
    <HeaderDesktop
      v-if="headerData && isDesktopOrTablet"
      class="hidden md:flex"
      :header-content="headerData"
    />
    <span class="absolute left-0 top-[75px] z-[-1] h-6 w-full bg-purple-extra-light md:top-20 md:h-9" />
    <HeaderMobile class="flex md:hidden" />
  </header>
</template>

<script setup lang="ts">
import {
  ChevronRightIcon,
  HeadsetIcon,
} from 'lucide-vue-next';

const { t } = useI18n();

const serviceHours = computed(() => 'Mo - Fr: 09:30 - 17:00');

const getPhoneNumberForLink = (phoneNumber: string) => phoneNumber.replace(/\s+/g, '').replace('(0)', '');

const {
  aboutUsLink,
  businessCustomersLink,
  cancellationPolicyLink,
  deliveryTimeLink,
  faqLink,
  giftVouchersLink,
  supportPhoneNumber,
  termsLink,
} = useFooterLinks();

const links = computed(() => [
  {
    link: faqLink.value,
    title: t('footer.customerService.faq'),
  },
  {
    link: giftVouchersLink.value,
    title: t('footer.customerService.giftVouchers'),
  },
  {
    link: deliveryTimeLink.value,
    title: t('footer.customerService.shipping'),
  },
  {
    link: deliveryTimeLink.value,
    title: t('footer.customerService.deliveryTime'),
  },
  {
    link: termsLink.value,
    title: t('footer.customerService.terms'),
  },
  {
    link: cancellationPolicyLink.value,
    title: t('footer.customerService.cancellationPolicy'),
  },
]);

const localePath = useLocalePath();
</script>

<template>
  <div class="container max-w-7xl">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:-mx-10">
      <div class="grid grid-cols-1 divide-y divide-grey-mid rounded-lg border border-grey-mid bg-white p-6 sm:col-span-2 sm:grid-cols-[3fr_2fr] sm:divide-x sm:divide-y-0 md:p-8 xl:p-10">
        <div class="flex flex-col justify-between gap-4 pb-6 sm:pb-0 sm:pr-6">
          <div class="@container">
            <h4 class="mb-2 text-lg font-bold text-dark md:text-xl">
              {{ $t('footer.customerService.headline') }}
            </h4>
            <div class="whitespace-pre-line text-sm">
              {{ $t('footer.customerService.subline') }}
            </div>
            <div class="whitespace-pre-line text-sm">
              <strong>{{ serviceHours }}</strong>.
            </div>

            <ul class="m-0 mt-4 grid list-none grid-cols-1 gap-x-4 gap-y-2 @xs:grid-cols-2">
              <li
                v-for="(link, index) in links"
                :key="index"
              >
                <a
                  class="flex items-center gap-1 text-sm font-medium text-dark"
                  :href="link.link"
                >
                  <ChevronRightIcon class="size-4 shrink-0 stroke-[3px]" />
                  {{ link.title }}
                </a>
              </li>
            </ul>
          </div>

          <div class="flex flex-col gap-4">
            <Button
              as-child
              class="w-full"
              variant="secondary"
            >
              <a
                class="flex items-center justify-center gap-1.5"
                :href="`tel:${getPhoneNumberForLink(supportPhoneNumber)}`"
              >
                <HeadsetIcon class="size-5" />
                {{ supportPhoneNumber }}
              </a>
            </Button>

            <div class="-mt-2 text-center text-xs italic text-grey-semidark md:-mb-6">
              {{ $t('footer.customerService.footnote') }}
              <NuxtLink
                class="underline"
                :to="businessCustomersLink"
              >
                {{ $t('footer.customerService.learnMore') }}
              </NuxtLink>
            </div>
          </div>
        </div>

        <div class="flex flex-col justify-between gap-4 pt-6 sm:pl-6 sm:pt-0">
          <div>
            <h4 class="mb-2 text-lg font-bold text-dark md:text-xl">
              {{ $t('footer.weAreJuniqe.headline') }}
            </h4>
            <div class="whitespace-pre-line text-sm">
              {{ $t('footer.weAreJuniqe.subline') }}
            </div>
          </div>

          <div class="relative -m-1 mt-0 flex aspect-21/9 flex-col justify-end overflow-hidden rounded-lg bg-grey-light p-1">
            <NuxtImg
              class="absolute inset-0 z-10 size-full object-cover"
              loading="lazy"
              provider="cloudflare"
              quality="80"
              sizes="400px, sm:200px, md:400px, lg:300px"
              src="/images/about-us.jpg"
              :alt="$t('footer.weAreJuniqe.headline')"
              :modifiers="{ f: 'auto' }"
            />
            <Button
              v-if="aboutUsLink"
              as-child
              class="relative z-20 w-full"
              variant="secondary"
            >
              <a
                class="flex items-center justify-center gap-1.5"
                :href="aboutUsLink"
              >
                {{ $t('footer.weAreJuniqe.aboutUs') }}
              </a>
            </Button>
          </div>
        </div>
      </div>

      <div class="group relative min-h-64 overflow-hidden rounded-lg bg-white shadow-xs sm:col-span-2 lg:col-span-1">
        <div class="inset-0 size-full items-center justify-center overflow-hidden transition-transform duration-200 group-hover:scale-105">
          <NuxtImg
            alt=""
            class="absolute object-cover object-top min-w-full min-h-full"
            decoding="async"
            fetchpriority="low"
            loading="lazy"
            provider="cloudflare"
            quality="80"
            sizes="100vw, lg:350px, xl:416px"
            src="/images/creators.jpg"
            :modifiers="{ f: 'auto' }"
          />
        </div>
        <div class="absolute inset-0 z-20 bg-linear-to-b from-transparent to-dark/50" />
        <div class="absolute top-0 z-30 flex h-full flex-col items-start justify-end gap-4 p-6 lg:px-8 xl:p-10">
          <div class="flex flex-col items-start justify-end gap-2">
            <h4 class="m-0 inline-block bg-white px-1 text-lg font-bold text-dark transition-transform duration-200 group-hover:-translate-y-2 md:text-xl">
              {{ $t('footer.becomeCreator.headline') }}
            </h4>
            <div class="whitespace-pre-line text-sm text-white transition-transform duration-200 group-hover:-translate-y-2">
              {{ $t('footer.becomeCreator.subline') }}
            </div>
          </div>

          <Button
            as-child
            class="w-56 max-w-full cursor-pointer"
            variant="secondary"
          >
            <NuxtLink :to="localePath({ path: '/creator-onboarding' })">
              {{ $t('footer.becomeCreator.learnMore') }}
            </NuxtLink>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CACHE_KEY_TAGS } from 'configs';
import { withQuery } from 'ufo';
import {
  getDurationInSeconds,
  Logger,
} from 'utils';

const { locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const {
  customer,
  fetchCustomer,
} = useCustomer();
const {
  fetchWishlist,
  migrateLocalStorageWishlist,
} = useWishlist();
const GqlInstance = useGql();
const route = useRoute();

const { isModalVisible } = useModalVisible();

const { switchLocale } = route.query;

if (switchLocale && switchLocale !== locale.value) {
  await navigateTo(withQuery(switchLocalePath(switchLocale as typeof locale.value), { switchLocale: undefined }), { replace: true });
}

const { data } = await useCachedAsyncData(
  `default-layout-${locale.value}`,
  async () => {
    try {
      return await GqlInstance(
        'DefaultLayout',
        { localeCode: locale.value },
      );
    } catch (error) {
      Logger.error('Could not fetch global content', error);
      return null;
    }
  },
  {
    clientMaxAge: 0,
    serverCacheTags: [
      CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT,
      CACHE_KEY_TAGS.NUXT_MULTI_CACHE_GLOBAL_CONTENT,
    ],
    serverMaxAge: getDurationInSeconds({ hours: 1 }),
  },
);

const relevantPromobar = data.value?.promobars.filter((promobar) => {
  const saleEnd = new Date(promobar.end).getTime();
  const now = new Date().getTime();
  return saleEnd > now;
}).sort((a, b) => new Date(a.end).getTime() - new Date(b.end).getTime())
  .shift();

onMounted(async () => {
  try {
    await fetchCustomer();
    await fetchWishlist();
    // TODO: remove this after some time when the outdated wishlist items are migrated
    migrateLocalStorageWishlist();
  } catch {}
});

// TODO: remove this after some time when the outdated wishlist items are migrated
watch(customer, migrateLocalStorageWishlist);
</script>

<template>
  <ConfigProvider>
    <div
      class="app"
      :class="{ 'modal-open': isModalVisible }"
    >
      <DelayHydration>
        <HeaderComponent
          v-if="data?.header"
          :header-data="data.header"
          :promobar="relevantPromobar"
        />
        <MainNavigation
          v-if="data?.mainNavigation.items"
          :main-navigation-items="data.mainNavigation.items"
        />
        <BurgerMenu
          v-if="data?.mainNavigation && data?.header"
          :header-data="data.header"
          :main-navigation-items="data.mainNavigation.items"
        />
      </DelayHydration>

      <slot />

      <FooterComponent
        v-if="data"
        :trustpilot-information="data.trustpilotInformation"
        :trustpilot-reviews="data.trustpilotReviews"
      />
      <DelayHydration>
        <AuthDialog />
        <NewsletterPopup />
      </DelayHydration>
    </div>
  </ConfigProvider>
</template>

<script setup lang="ts">
import {
  ArrowLeftIcon,
  KeyIcon,
  SendIcon,
} from 'lucide-vue-next';
import { Logger } from 'utils';
import {
  object,
  string,
} from 'yup';

import { useDialog } from '@/composables/useDialog';
import type { AuthDialogView } from '@/types/dialogs';

const { setView } = useDialog<AuthDialogView>('AuthDialog', 'login');

const { forgotPassword } = useCustomer();
const submitted = ref(false);

const serverError = ref<string | null>(null);
const { handleSubmit } = useForm({
  initialValues: {},
  validationSchema: toTypedSchema(object({
    email: string().required('form.errorMessage.required')
      .email('form.errorMessage.email'),
  })),
});

const onSubmit = handleSubmit(async ({ email }) => {
  try {
    await forgotPassword(email);
    submitted.value = true;
  } catch (e) {
    if (e instanceof Error) {
      serverError.value = e.message;
    }
    Logger.error('Could not send forgot password email', e);
  }
});
</script>

<template>
  <form @submit.prevent="onSubmit()">
    <div class="relative flex flex-col items-center justify-center bg-white p-8 text-center">
      <Button
        class="absolute left-4 top-4 size-10 rounded-full border border-grey-light p-0 text-dark shadow-none hover:border-grey-mid! focus-visible:ring-1"
        variant="outline"
        @click="setView('login')"
      >
        <ArrowLeftIcon class="size-6" />
      </Button>

      <template v-if="submitted">
        <SendIcon class="mb-2 size-10" />
        <h2 class="my-0 text-xl font-bold">
          {{ $t('authDialog.forgotPassword.success.headline') }}
        </h2>
        <p class="mt-1 font-medium">
          {{ $t('authDialog.forgotPassword.success.description') }}
        </p>
      </template>
      <template v-else>
        <KeyIcon class="mb-2 size-10" />
        <h2 class="my-0 text-xl font-bold">
          {{ $t('authDialog.forgotPassword.headline') }}
        </h2>
        <p class="mb-0 mt-1 font-medium">
          {{ $t('authDialog.forgotPassword.description') }}
        </p>
      </template>
    </div>
    <div
      class="relative p-8"
      style="background-image: linear-gradient(to bottom, var(--color-grey-light) 0, var(--color-white) 1rem)"
    >
      <template v-if="submitted">
        <Button
          class="w-full"
          type="button"
          variant="info"
          @click="setView('login')"
        >
          {{ $t('authDialog.forgotPassword.success.submit') }}
        </Button>
      </template>
      <template v-else>
        <div class="grid grid-cols-1 gap-5">
          <UiAlert
            v-if="serverError"
            type="error"
            :body="serverError"
          />
          <FormField
            v-slot="{ componentField }"
            name="email"
          >
            <FormItem>
              <FormLabel :label="$t('authDialog.forgotPassword.email')" />
              <FormControl>
                <Input
                  :id="componentField.name"
                  v-bind="componentField"
                  autocomplete="email"
                />
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>

          <Button
            class="w-full"
            type="submit"
            variant="info"
          >
            {{ $t('authDialog.forgotPassword.submit') }}
          </Button>
        </div>
      </template>
    </div>
  </form>
</template>

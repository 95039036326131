<script setup lang="ts">
import { UserCircleIcon } from 'lucide-vue-next';
import { useDialog } from '@/composables/useDialog';
import type { AuthDialogView } from '@/types/dialogs';

const { customer } = useCustomer();
const { locale } = useI18n();
const { openDialog } = useDialog<AuthDialogView>('AuthDialog', 'login');

const isLoggedIn = ref(false);

onMounted(() => {
  isLoggedIn.value = customer.value?.isLoggedIn ?? false;
});

watch(
  () => customer.value?.isLoggedIn,
  (newValue) => {
    isLoggedIn.value = newValue ?? false;
  },
);
</script>

<template>
  <component
    :is="isLoggedIn ? 'a' : 'button'"
    class="flex size-8 items-center justify-center rounded-full border border-grey bg-white transition duration-300 ease-in-out hover:border-2 hover:border-dark md:size-10"
    type="button"
    :href="isLoggedIn ? `/${locale}/account` : null"
    @click="!isLoggedIn && openDialog()"
  >
    <div
      v-if="isLoggedIn"
      class="flex size-6 items-center justify-center rounded-full bg-dark"
    >
      <span
        class="text-sm font-bold uppercase text-white"
        v-text="customer?.firstname?.charAt(0)"
      />
    </div>
    <UserCircleIcon
      v-else
      class="size-6 text-dark"
    />
  </component>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  isLoading?: boolean;
  shouldCoverEntirePage?: boolean;
}>(), { isLoading: false, })

const overlayClasses = computed(() => ({
  'absolute h-[3000px]': !__props.shouldCoverEntirePage,
  'fixed h-full': __props.shouldCoverEntirePage,
  'w-full': __props.isLoading,
}));
</script>

<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="loading-overlay top-0 z-50 bg-dark/40"
      :class="overlayClasses"
    />
  </transition>
</template>

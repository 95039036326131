<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router';
import { productGroupMap } from 'configs';
import { ChevronDownIcon } from 'lucide-vue-next';
import {
  PRODUCT_TYPE_PARAM,
  SEARCH_PARAM,
} from '@/config/filter';

const {
  getActiveFilters,
  getSearchParams,
  setSearchParams,
  toggleActiveFilters,
} = useActiveFilters();

const route = useRoute();
const localePath = useLocalePath();
const router = useRouter();
const routeQueryProductType = useRouteQuery<string>(PRODUCT_TYPE_PARAM);
const selectedProductType = ref(routeQueryProductType.value || '');

const navigateToResultPage = async (productType: string) => {
  if (productType !== '') {
    const searchTerm = 'q' in router.currentRoute.value.query
      ? router.currentRoute.value.query.q as string
      : null;
    const queryObject: { [PRODUCT_TYPE_PARAM]?: string; [SEARCH_PARAM]?: string } = {};
    queryObject[PRODUCT_TYPE_PARAM] = productType;

    if (searchTerm) {
      queryObject[SEARCH_PARAM] = searchTerm;
    }

    if (route.name.includes('search')) {
      if (getSearchParams?.value?.productType && getSearchParams.value?.productType !== productType) {
        toggleActiveFilters({
          filterCategoryName: 'product',
          filterKey: getSearchParams.value.productType,
          wasFilterSelected: true,
        });
      }

      if (productType && getSearchParams?.value?.productType !== productType) {
        toggleActiveFilters({
          filterCategoryName: 'product',
          filterKey: selectedProductType.value,
          wasFilterSelected: false,
        });
      }
    }

    setSearchParams({
      productType,
      query: searchTerm || null,
    });

    await navigateTo({
      path: localePath({ path: '/search' }),
      query: queryObject,
    });
  }
};

watch(
  () => routeQueryProductType.value,
  (newProductType) => {
    if (typeof newProductType === 'undefined' && getSearchParams?.value?.productType !== null) {
      selectedProductType.value = getSearchParams.value.productType
        ? getSearchParams.value.productType
        : '';
    } else if (!newProductType) {
      selectedProductType.value = '';
    } else {
      selectedProductType.value = newProductType;
    }
  },
);

watch(
  () => route.name,
  () => {
    const isPageNotSearchContext = [
      'search',
      'product',
    ].some((entry) => route.name.includes(entry));
    if (!isPageNotSearchContext) {
      selectedProductType.value = '';
    }
  },
);

watch(
  getActiveFilters,
  (newFilters, oldFilters) => {
    if (
      selectedProductType.value
       && oldFilters.product?.includes(selectedProductType.value)
       && !newFilters.product?.includes(selectedProductType.value)
    ) {
      selectedProductType.value = '';
    }
  },
);

const topProducts = [
  'poster',
  'leinwand',
  'notebook',
];
</script>

<template>
  <label
    class="product-type-select relative order-1 h-full w-auto cursor-pointer overflow-hidden rounded-s-3xl"
    :class="[ !selectedProductType ? 'min-w-fit max-w-10' : 'max-w-32 md:max-w-[50%] min-[800px]:min-w-6 min-[1000px]:min-w-fit ml:w-auto ml:max-w-[50%]']"
  >
    <div class="relative flex h-full items-center bg-grey-light">
      <span class="truncate text-nowrap pl-3 text-xs font-semibold md:text-sm">
        {{ selectedProductType ? $t(`product.type.${selectedProductType}`) : $t('common.allProducts') }}
      </span>
      <span class="ml-1 mr-2 size-4">
        <ChevronDownIcon class="size-4" />
      </span>
    </div>
    <select
      v-model="selectedProductType"
      class="absolute left-0 top-0 cursor-pointer bg-transparent opacity-0"
      @change="navigateToResultPage(selectedProductType)"
    >
      <option value="">{{ $t('common.allProducts') }}</option>
      <hr />
      <optgroup :Label="$t('common.topProducts')">
        <option
          v-for="product in topProducts"
          :key="`top-${product}`"
          :value="product"
        >{{ $t(`product.type.${product}`) }}</option>
      </optgroup>
      <template
        v-for="group in productGroupMap"
        :key="group.name"
      >
        <hr />
        <optgroup :label="$t(`product.category.${group.name}`)">
          <option
            v-for="product in group.products"
            :key="product"
            :value="product"
          >{{ $t(`product.type.${product}`) }}</option>
        </optgroup>
      </template>
    </select>
  </label>
</template>

<script setup lang="ts">
import { HeartIcon } from 'lucide-vue-next';

import { useDialog } from '@/composables/useDialog';
import type { AuthDialogView } from '@/types/dialogs';

const ANIMATION_DURATION = 1000;

const { customer } = useCustomer();
const localePath = useLocalePath();
const {
  isLoading: wishlistLoading,
  wishlistItems,
} = useWishlist();
const { openDialog } = useDialog<AuthDialogView>('AuthDialog', 'login');

const shouldAnimate = ref(false);

const wishlistCount = computed(() => wishlistItems.value?.length ?? 0);

const onClick = () => {
  if (!customer.value?.id) {
    openDialog('login', {
      afterClose: () => {
        if (!customer.value?.id) {
          return;
        }

        navigateTo(localePath({ path: '/wishlist' }));
      },
    });
    return;
  }

  navigateTo(localePath({ path: '/wishlist' }));
};

watch([
  wishlistCount,
  wishlistLoading,
], ([ newCount ], [
  oldCount,
  hasBeenLoading,
]) => {
  if (hasBeenLoading) {
    return;
  }

  if (newCount > 0 && newCount > (oldCount ?? 0)) {
    shouldAnimate.value = true;

    setTimeout(() => {
      shouldAnimate.value = false;
    }, ANIMATION_DURATION);
  }
});
</script>

<template>
  <div class="relative">
    <button
      class="relative flex size-8 items-center justify-center rounded-full border border-grey bg-white transition-colors ease-in-out hover:border-dark hover:ring-1 hover:ring-dark md:ml-4 md:size-10"
      :class="{ 'hop-animation': shouldAnimate }"
      @click.prevent="onClick"
    >
      <span
        class="absolute inset-0 scale-0 rounded-full bg-purple-light"
        :class="{ 'button-fill-animation': shouldAnimate }"
      />
      <HeartIcon
        class="relative z-20 size-5 text-dark md:size-6"
        :class="{ 'heart-spin-animation': shouldAnimate }"
      />
      <ClientOnly>
        <CountBadge
          v-if="wishlistCount > 0"
          has-border
          class="z-30"
          color="purple"
          :count="wishlistCount"
        />
      </ClientOnly>
    </button>
  </div>
</template>

<style scoped>
.heart-spin-animation {
  animation-name: heart-spin;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes heart-spin {
  0% {  transform: rotateY(0deg) translateY(0px); fill: transparent; stroke: var(--color-dark); }
  50% {  transform: rotateY(180deg) translateY(-4px); fill: var(--color-purple); stroke: var(--color-purple); }
  80% {  transform: rotateY(360deg) translateY(2px); }
  100% {  transform: rotateY(360deg) translateY(0px); fill: transparent; stroke: var(--color-dark); }
}

.button-fill-animation {
  animation-name: button-fill;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes button-fill {
  0% {  transform: scale(0); }
  50% {  transform: scale(1); }
  100% {  transform: scale(0); }
}

.hop-animation {
  animation-name: hop;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes hop {
  0% {  transform: translateY(0px); }
  50% {  transform: translateY(-4px); }
  100% {  transform: translateY(0px); }
}
</style>

<script setup lang="ts">
import { ArrowRightIcon } from 'lucide-vue-next';

const UPDATE_DELAY = 150;

const nuxtApp = useNuxtApp();

const GqlInstance = useGql();

withDefaults(defineProps<{ flyoutItemId: string }>(), {  })

const {
  closeFlyout,
  firstLevelActiveIndex,
  secondLevelActiveIndex,
  setHasItems,
  updateFirstLevelActiveIndex,
  updateSecondLevelActiveIndex,
} = useNavigationActiveIndex();

const flyout = ref(null);

const { locale } = useI18n();

const { data } = await useAsyncData(
  `navigation-item-${__props.flyoutItemId}-${locale.value}`,
  () => GqlInstance('NavigationItem', {
    id: __props.flyoutItemId,
    localeCode: locale.value,
  }),
  { getCachedData: (key) => (nuxtApp.static.data[key] || nuxtApp.payload.data[key]) },
);

const navigationItem = computed(() => data.value?.navigationItem);

const secondLevelData = computed(() => navigationItem.value?.groups?.[secondLevelActiveIndex.value]);

const hasFlyoutItems = computed(() => !!navigationItem.value?.groups?.length);

onClickOutside(flyout, () => {
  closeFlyout();
});

const {
  start,
  stop,
} = useTimeoutFn((index: number) => {
  updateSecondLevelActiveIndex(index || 0);
}, UPDATE_DELAY);

const updateSecondLevelDelayed = (index: number) => {
  start(index);
};

watchEffect(() => {
  if (hasFlyoutItems.value) {
    setHasItems(true);
  }
});
</script>

<template>
  <div
    v-if="hasFlyoutItems"
    class="flyout-navigation absolute top-11 z-51 h-screen w-full border-t border-grey-light bg-dark/1"
  >
    <div class="bg-purple-extra-light lg:bg-linear-to-r lg:from-grey-light lg:from-50% lg:to-white lg:to-50%">
      <div
        ref="flyout"
        class="content-box container relative flex max-h-[calc(100vh-205px)] w-full overflow-auto hyphens-auto whitespace-break-spaces p-0 md:flex"
        @mouseenter="updateFirstLevelActiveIndex(firstLevelActiveIndex)"
        @mouseleave="closeFlyout()"
      >
        <div class="grid h-auto max-w-64 grid-cols-1 content-start overflow-y-auto overflow-x-hidden bg-purple-extra-light py-5 pl-5 lg:min-w-[250px] lg:pt-7">
          <span
            v-for="(item, index) in navigationItem?.groups"
            v-show="item.columns && item.columns.length"
            :key="index"
            class="relative flex justify-between p-3 transition"
            :class="{
              'translate-x-2.5 rounded-lg bg-white font-semibold': index === secondLevelActiveIndex,
            }"
            @mouseleave="stop"
            @mouseover="updateSecondLevelDelayed(index)"
            @touchstart.prevent="updateSecondLevelActiveIndex(index)"
          >
            <span
              class="max-w-[75%] cursor-default text-sm text-dark hover:text-semidark"
              v-text="item.title"
            />
            <ArrowRightIcon class="mr-7 size-3 self-center lg:mr-3" />
          </span>
        </div>
        <FlyoutColumnsDesktop
          v-if="secondLevelData"
          :navigation-item-group="secondLevelData"
        />
        <FlyoutColumnsTablet
          v-if="secondLevelData"
          :navigation-item-group="secondLevelData"
        />
      </div>
    </div>
  </div>
</template>
